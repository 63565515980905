<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel
            title="DAFTAR UNIT PEMERINTAH DAERAH"
            class="panel panel-success"
        >
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> LIST PEMBERI PARTISIPASI</h6>
            </template> -->
            <div class="pl-4 py-2 border shadow-sm">
                <span class="px-2"
                    ><b-button variant="primary" @click="create" pill
                        ><i class="fa fa-plus pr-1"></i> Tambah Unit</b-button
                    ></span
                >
                <span class="px-2"
                    ><b-button variant="primary" @click="reload" pill
                        ><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button
                    ></span
                >
            </div>
            <div class="py-3">
                <b-card class="shadow-sm rounded-0">
                    <!-- filter section -->
                    <b-row>
                        <b-col md="6" class="mb-3">
                            <b-form-group
                                :label-cols="3"
                                label="Filter By"
                                class="mb-0 my-1"
                            >
                                <b-input-group size="md">
                                    <b-form-select
                                        v-model="filterBy"
                                        :options="fieldOptions"
                                        @change="changeFilterBy()"
                                    >
                                        <option slot="first" :value="'all'"
                                            >All Data</option
                                        >
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group
                                :label-cols="3"
                                label="Filter"
                                class="mb-0"
                                description="Type to Search or Click Clear to Stop Searching "
                            >
                                <b-input-group size="md">
                                    <b-form-input
                                        v-model="filter"
                                        placeholder="Type to Search"
                                        debounce="500"
                                    />
                                    <b-input-group-append>
                                        <b-btn
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            variant="info"
                                            >Clear</b-btn
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="mt-1">
                            <b-form-group
                                :label-cols="3"
                                label="Sort"
                                class="mb-0 my-1"
                            >
                                <b-input-group size="md">
                                    <b-form-select
                                        v-model="sortBy"
                                        :options="fieldOptions"
                                    >
                                        <option slot="first" :value="null"
                                            >-- none --</option
                                        >
                                    </b-form-select>
                                    <b-form-select
                                        :disabled="!sortBy"
                                        v-model="sortDesc"
                                        slot="append"
                                    >
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!-- end filter section -->
                    <!-- end Tools & Search -->

                    <!-- Data Table & Pagination -->
                    <b-table
                        show-empty
                        small
                        id="masTable"
                        ref="masTable"
                        head-variant="light"
                        :hover="true"
                        :busy.sync="isBusy"
                        :items="myGetData"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        bordered
                    >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <!-- <template #cell(urusan_pd)="data">
                            {{ data.item.urusan_pd.nama }}
                        </template> -->

                        <template v-slot:table-colgroup="scope">
                            <col
                                v-for="field in scope.fields"
                                :key="field.key"
                                :style="{
                                    width:
                                        field.key === 'actions' ? '105px' : ''
                                }"
                            />
                        </template>
                        <template #head()="data">
                            <span style="white-space: pre;">{{
                                data.label
                            }}</span>
                        </template>

                        <!-- button actions -->
                        <template v-slot:cell(actions)="data">
                            <b-dropdown
                                split
                                class="d-flex align-item-center"
                                size="sm"
                                variant="info"
                            >
                                <template #button-content>
                                    <i class="fa fa-cogs pr-1"></i> Aksi
                                </template>
                                <!-- <b-dropdown-item @click="view(data.item.id)"
                                    ><i class="fa fa-eye"></i>
                                    Lihat</b-dropdown-item
                                > -->
                                <b-dropdown-item @click="edit(data.item.id)"
                                    ><i class="fa fa-edit"></i>
                                    Edit</b-dropdown-item
                                >
                                <b-dropdown-item
                                    @click="
                                        swalNotification('error', data.item.id)
                                    "
                                    ><i class="fa fa-trash-alt"></i>
                                    Hapus</b-dropdown-item
                                >
                            </b-dropdown>
                        </template>
                        <!-- end button actions -->
                    </b-table>
                    <div class="divider"></div>

                    <!-- pagination section -->
                    <b-row>
                        <div class="divider"></div>
                        <b-col md="6" class="my-0">
                            <b-form-group
                                :label-cols="2"
                                label="Per page"
                                class="mb-0"
                            >
                                <b-form inline>
                                    <b-form-select
                                        :options="pageOptions"
                                        style="width: 100px;"
                                        v-model="perPage"
                                    />
                                    <label class="ml-1 mr-1">/</label>
                                    <b-form-input
                                        :disabled="true"
                                        v-model="totalRows"
                                        style="width: 100px;"
                                        class="text-right"
                                    />
                                    <label class="ml-1">Rows</label>
                                </b-form>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="my-0">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="md"
                                class="my-0 "
                            ></b-pagination>
                        </b-col>
                    </b-row>
                    <!-- end pagination section -->

                    <!-- end Data Table & Pagination -->
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
        <!--- modal view -->
        <b-modal
            title="Detail Klasifikasi"
            v-model="showModalView"
            id="modal-view"
            hide-footer
            no-close-on-backdrop
        >
            <form>
                <div class="form-group row ml-1 mb-1">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Urusan
                    </label>
                    <div class="col-md-6">
                        <v-select
                            label="nama"
                            :options="urusanOptions"
                            :reduce="nama => nama.id"
                            v-model="form.urusan_pd_id"
                            placeholder="Pilih Urusan"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row ml-1 mb-1">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.kode"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row ml-1 mb-1">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Nama Klasifikasi
                    </label>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('nama')
                                    }"
                                    v-model="form.nama"
                                    placeholder="Masukkan Nama Klasifikasi"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
        <!-- Modal Tambah transaksi -->
        <b-modal
            :title="
                editMode ? 'Edit Data Klasifikasi' : 'Tambah Data Klasifikasi'
            "
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
        >
            <form
                @submit.prevent="
                    editMode ? update(detailKlasifikasiMixin.id) : store()
                "
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Perangkat
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="PemerintahDaerah.perangkat"
                            :reduce="nama => nama"
                            v-model="form.perangkat_pd_id"
                            placeholder="Pilih Perangkat"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('perangkat_pd_id')"
                            class="form-text text-danger"
                            >{{ errors.perangkat_pd_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true
                                            }"
                                            v-model="kode.perangkat"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true
                                            }"
                                            v-model="kode.unit"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('kode')"
                                    class="form-text text-danger"
                                    >{{ errors.kode[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Nama Unit <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('nama')
                                    }"
                                    v-model="form.nama"
                                    placeholder="Masukkan Nama Perangkat"
                                />
                                <em
                                    v-if="form.errors.has('nama')"
                                    class="form-text text-danger"
                                    >{{ errors.nama[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode Urusan
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-3">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'kd_urusan'
                                        )
                                    }"
                                    v-model="form.kd_urusan"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode Bidang
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-3">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'kd_bidang'
                                        )
                                    }"
                                    v-model="form.kd_bidang"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode OPD
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-3">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('kd_opd')
                                    }"
                                    v-model="form.kd_opd"
                                    @input="inputkdOPD(form.kd_opd)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode Sub OPD
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-3">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'kd_sub_opd'
                                        )
                                    }"
                                    v-model="form.kd_sub_opd"
                                    @input="inputkdOPD(form.kd_sub_opd)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode PD
                    </label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-5">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                    }"
                                    v-model="form.kode_pd"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Alamat <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form-textarea
                                    id="textarea-large"
                                    size="lg"
                                    placeholder="Masukkan Alamat"
                                    rows="4"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'alamat'
                                        )
                                    }"
                                    v-model="form.alamat"
                                ></b-form-textarea>
                                <em
                                    v-if="form.errors.has('alamat')"
                                    class="form-text text-danger"
                                    >{{ errors.alamat[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Provinsi <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="WilayahIndonesia.provinsi"
                                    :reduce="nama => nama.id"
                                    v-model="wilayah.provinsi"
                                    @input="inputProvinsi(wilayah.provinsi)"
                                    placeholder="Pilih Provinsi"
                                >
                                </v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >Isian Provinsi harus diisi.</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kab./Kota <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="WilayahIndonesia.kabkota"
                                    :reduce="nama => nama.id"
                                    v-model="wilayah.kabkota"
                                    @input="inputKabkota(wilayah.kabkota)"
                                    placeholder="Pilih Kab./Kota"
                                >
                                </v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >Isian Kab./Kota harus diisi.</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kecamatan <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="WilayahIndonesia.kecamatan"
                                    :reduce="nama => nama.id"
                                    v-model="wilayah.kecamatan"
                                    @input="inputKecamatan(wilayah.kecamatan)"
                                    placeholder="Pilih Kecamatan"
                                >
                                </v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >Isian Kecamatan harus diisi.</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kelurahan <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="WilayahIndonesia.kelurahan"
                                    :reduce="nama => nama.id"
                                    v-model="form.wilayah_kelurahan_id"
                                    placeholder="Pilih Kelurahan"
                                >
                                </v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >{{ errors.wilayah_kelurahan_id[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <span>
                    <b-button variant="primary" type="submit">
                        <span v-if="isLoading">
                            <b-spinner
                                variant="primary"
                                label="Spinning"
                                small
                            ></b-spinner>
                        </span>
                        <i class="fa fa-save" v-else></i>
                        Simpan
                    </b-button>
                </span>
                <span class="float-right">
                    <b-button variant="outline-secondary" @click="close"
                        ><i class="fa fa-times"></i> Tutup</b-button
                    >
                </span>
            </form>
        </b-modal>
        <!-- end Modal -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";
import { mapState } from "vuex";
import hapusToken from "@/helper/hapusLocalStore";
import dataKlasifikasi from "@/helper/api/pemerintah_daerah.js";

export default {
    mixins: [hapusToken, dataKlasifikasi],
    components: {
        HeadTitle
    },
    data() {
        return {
            selected: "1",
            imageLogo: Logo,
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            roleArr: ["Maintenance", "Administrator", "Pimpinan", "Pegawai"],
            role: JSON.parse(localStorage.getItem("roles"))[0],
            fields: [
                {
                    key: "index",
                    label: "No."
                },
                {
                    key: "kode",
                    label: "KODE",
                    sortable: true
                },
                {
                    key: "nama",
                    label: "UNIT",
                    sortable: true
                },
                {
                    key: "kd_urusan",
                    label: "KODE URUSAN",
                    sortable: true
                },
                {
                    key: "kd_bidang",
                    label: "KODE BIDANG",
                    sortable: true
                },
                {
                    key: "kd_opd",
                    label: "KODE OPD",
                    sortable: true
                },
                {
                    key: "kd_sub_opd",
                    label: "KODE SUB OPD",
                    sortable: true
                },
                {
                    key: "kode_pd",
                    label: "KODE PD",
                    sortable: true
                },
                {
                    key: "actions",
                    label: "opsi",
                    sortable: false
                }
            ],
            status_id: null,
            editMode: false,
            showModalForm: false,
            showModalView: false,
            form: new Form({
                perangkat_pd_id: "",
                wilayah_kelurahan_id: "",
                alamat: "",
                kode: "",
                nama: "",
                kd_urusan: "",
                kd_bidang: "",
                kd_opd: "",
                kode_pd: "",
                kd_sub_opd: ""
            }),
            errors: [],
            prov_id: "",
            isLoading: false,
            urusanOptions: [],
            kode: {
                perangkat: "",
                unit: ""
            },
            wilayah: {
                provinsi: "",
                kabkota: "",
                kecamatan: ""
            }
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState(["filterTest", "PemerintahDaerah", "WilayahIndonesia"])
    },
    mounted() {
        this.status_id = JSON.parse(localStorage.getItem("statusRegistrasi"));
        this.$store.dispatch("PemerintahDaerah/getPerangkat");
        this.$store.dispatch("WilayahIndonesia/getProvinsi");
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/pemerintah-daerah/unit-pd", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    statusRegistrasiId: this.status_id
                }
            });
            return promise
                .then(response => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
            localStorage.removeItem("filterView");
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/pemerintah-daerah/unit-pd/" + id)
                        .then(response => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000
                            }).catch(error => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditNPWP", params: { id: index } });
        },
        filterStatus(number) {
            this.status_id = number;
            this.reload();
        },
        // show modal
        create() {
            this.form.reset();
            this.form.clear();
            this.editMode = false;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // show modal
        edit(id) {
            this.form.clear();
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
            // dataKlasifikasi Mixin
            this.getDetailKlasifikasi(id);
        },
        view(id) {
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal-view");
            this.showModalView == true;
            // dataJabatan Mixin
            this.getDetailKlasifikasi(id);
        },
        close() {
            this.form.reset();
            this.$root.$emit("bv::hide::modal", "modal");
            this.showModalForm == false;
        },
        // store data provinsi
        store() {
            this.isLoading = true;
            this.form
                .post("/api/pemerintah-daerah/unit-pd")
                .then(response => {
                    this.isLoading = false;
                    this.form.reset();
                    this.kode = {
                        klasifikasi: "",
                        urusan: ""
                    };
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                        timer: 5000
                    });
                    this.reload();
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapusToken mixin
                        this.clearAll();
                    }
                });
        },
        // store data provinsi
        update(id) {
            this.isLoading = true;
            this.form
                .put("/api/pemerintah-daerah/klasifikasi-pd/" + id)
                .then(response => {
                    this.isLoading = false;
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false
                    });
                    this.reload();
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                });
        },

        // input urusan
        inputUrusan(value) {
            if (value) {
                this.$store.dispatch(
                    "PemerintahDaerah/getKlasifikasi",
                    value.id
                );
                this.form.urusan_pd_id = value.id;
                this.kode.kode = value.kode;
            } else {
                this.$store.commit("PemerintahDaerah/inputKlasifikasi", []);
                this.kode = {
                    urusan: "",
                    kodeUrusan: "",
                    klasifikasi: "",
                    kodeKlasifikasi: "",
                    perangkat: ""
                };
            }
        },

        // input Klasifikasi
        inputKlasifikasi(value) {
            if (value) {
                this.form.klasifikasi_pd_id = value.id;
                this.kode.kode = value.kode;
            } else {
                this.$store.commit("PemerintahDaerah/inputKlasifikasi", []);
                this.kode = {
                    klasifikasi: "",
                    kodeKlasifikasi: "",
                    perangkat: ""
                };
            }
        },

        // wilayah
        // provinisi
        inputProvinsi(id) {
            if (id) {
                this.$store.dispatch("WilayahIndonesia/getKabkota", id);
            } else {
                this.$store.commit("WilayahIndonesia/kabkota", []);
                this.$store.commit("WilayahIndonesia/kecamatan", []);
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.wilayah = {
                    kabkota: "",
                    kecamatan: ""
                };
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // kabkota
        inputKabkota(id) {
            if (id) {
                this.$store.dispatch("WilayahIndonesia/getKecamatan", id);
            } else {
                this.$store.commit("WilayahIndonesia/kecamatan", []);
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.wilayah = {
                    kabkota: "",
                    kecamatan: ""
                };
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // kecamatan
        inputKecamatan(id) {
            if (id) {
                this.$store.dispatch("WilayahIndonesia/getKelurahan", id);
            } else {
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.wilayah = {
                    kecamatan: ""
                };
                this.form.wilayah_kelurahan_id = "";
            }
        }
    }
};
</script>

<style scoped>

</style>
